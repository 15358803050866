import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation,useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const VerifyLinkedin = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('Data fetched un-successfully!');
    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem("token")) {
            navigate("/home", {
                replace: true,
            });
        }
    }, [navigate]);
   


    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const urlParam = queryParams.get('url');
        const signature = queryParams.get('signature');

        if (urlParam) {
            axios.get(`${urlParam}&signature=${signature}`)
                .then(response => {
                    console.log(response.data);
                    if(response.data.status){
                        localStorage.setItem("isLogin", true);
                        localStorage.setItem("token", response.data.access_token);
                        localStorage.setItem("email", response.data.email);
                        localStorage.setItem("name", response.data.name);
                        localStorage.setItem("campaign_id", response.data.campaign_id);
                        localStorage.setItem("is_show_referal", response.data.show_referal);
                        navigate("/home", {
                            replace: true,
                        });
            
                        setLoadingText('Data fetched successfully!');
                        window.location.reload();
                    }
                })
                .catch(error => console.error('Error fetching data:', error))
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error('No URL parameter provided');
            setLoading(false);
        }
    }, [location.search]);

    return (
        <>
        <Header />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            {loading ? (
                <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                <div>
                    <h1>LinkedIn Verification</h1>
                    <p>{loadingText}</p>
                </div>
            )}
        </div>
        </>
    );
}

export default VerifyLinkedin;
