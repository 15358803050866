import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import "./assets/css/style.css";
import Universities from "./pages/Universities";
import OTP from "./pages/Otp";
import VerifyLinkedin from "./pages/VerifyLinkedin";
import Login from "./pages/Login";
import Referal from "./pages/Referal";
import ReferalViralLoops from "./pages/ReferalViralLoops";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Disclaimer from "./pages/Disclaimer";
import Cookiepolicy from "./pages/Cookiepolicy";

// const root = ReactDOM.createRoot(document.getElementById('root'));

const RootComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/sign-up" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/otp" element={<OTP />} />
      <Route path="/review" element={<Referal />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<Cookiepolicy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/referals" element={<ReferalViralLoops />} />
      <Route path="/verify-linkedin" element={<VerifyLinkedin />} />
      <Route path="/universites/:name" element={<Universities />} />
    </Routes>
  );
};

const Root = () => (
  <Router>
    <RootComponent />
  </Router>
);

createRoot(document.getElementById("root")).render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
