import React, { useState, useEffect } from 'react';
import * as d3 from "d3";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../assets/fontawesome/css/fontawesome.min.css';
import "../assets/css/style.css";
import searchIcon from "../assets/images/countries/searchIcon.jpg";
import slugify from 'slugify';
import star from "../assets/images/icon/star.png";
import university from "../assets/images/icon/university.png";
import warwick from "../assets/images/university/warwick.jpg";
import graduation from "../assets/images/icon/graduation.png";
import user1 from "../assets/images/review/user1.jpg";
import user2 from "../assets/images/review/user2.jpg";
import user3 from "../assets/images/review/user3.jpg";
import bgImage from "../assets/images/bg.png";
import sanikaUserImage from "../assets/images/countries/sanika.jpg";
import mihirImage from "../assets/images/countries/mihir.jpg";
import rating from "../assets/images/review/rating.png";
import quotes from "../assets/images/review/quotes.png";
import reviewUserProfile from "../assets/images/review/img1.jpeg";
import Slider from "react-slick";
import UniversitySelect from '../components/UniversitySelect';
import upRightImage from "../assets/images/icon/up-right.png"
import MapVisualization from '../components/MapVisualization';
import Charts from '../components/Charts';
import ShowMoreText from "react-show-more-text";
import usaImage from "../assets/images/countries/statue-liberty-foreground.jpg";
import ukImage from "../assets/images/countries/panoramic-view-big-ben-london-sunset-uk.jpg";
import ausImage from "../assets/images/countries/sydney-opera-house-is-lit-up-evening-sun.jpg";
import canadaImage from "../assets/images/countries/toronto-skyline-sunset-with-cn-tower.jpg";
import nzImage from "../assets/images/countries/auckland-city-panorama-new-zealand-auckland-commercial-hub-major-travel-port-new-zealand-otherwise-known-as-city-sails-this-panorama-auckland.jpg";
import switzerlandImage from "../assets/images/countries/cityscape-night-zurich-switzerland.jpg";
import singaporeImage from "../assets/images/countries/singapore-cityscape-twilight.jpg";
import germanyImage from "../assets/images/countries/annobon-iamazing-berlin-germanysland-guinea.jpg";
import sandeepImage from "../assets/images/home/sandeep.png";
import userImage from "../assets/images/profile/user.jpg"

const apiUrl = process.env.REACT_APP_API_URL;



const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 5000,
    cssEase: 'linear',
    arrows: false,
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const sliderSettings2 = {
    slidesToShow: 3,
        slidesToScroll: 1,
        rtl: true,
        infinite: true,
        loop:true, 
        autoplay: true,
        autoplaySpeed: 0,
        speed: 5000,
        cssEase: 'linear',
        pauseOnHover: true,
        pauseOnFocus: true,
        arrows: false,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const sliderSettings3 = {
        slidesToShow: 3,
        slidesToScroll: 1,
        rtl: true,
        infinite: true,
        loop:true, 
        autoplay: true,
        autoplaySpeed: 0,
        speed: 5000,
        cssEase: 'linear',
        pauseOnHover: false,
        pauseOnFocus: true,
        arrows: false,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };


    

const Home = () => {

   

    const [reviews, setReviews] = useState([]);
    const [reviews2, setReviews2] = useState([]);
    const [universitiesList, universities] = useState([]);
    const location = useLocation();

    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    };

    const countryImagePickr = (countryName) => {
        switch (countryName) {
            case "United States":
                return usaImage;
            break;
            case "United Kingdom":
                return ukImage;
            break;
            case "Australia":
                return ausImage;
            break;
            case "Singapore":
                return singaporeImage;
            break;
            default:
                return usaImage;
        }
    }


    useEffect(() => {
        const getQueryParams = (query) => new URLSearchParams(query);
        const queryParams = getQueryParams(location.search);
        const referal = queryParams.get("referal_id");
        if(referal !== null){
          localStorage.setItem("referal",referal);
        }
    }, []);


    
    useEffect(() => {
        // Fetch reviews from the API
          axios.get(`${apiUrl}getTypeformReview`, { params: { ids: ["austine.dsouza27@gmail.com", "chetanmanke9@gmail.com", "gonelamanikanteshmani@gmail.com","udhrupad@gmail.com","krunaldasri19@gmail.com"] } })
      .then(response => {
        setReviews(response.data);
      })
      .catch(error => console.error('Error fetching reviews for slider 1:', error));
          axios.get(`${apiUrl}getTypeformReview`, { params: { ids: ["uday123vekariya@gmail.com", "rajdeepkaursaggu@gmail.com","admissionkrushang@gmail.com","tanviambre97@gmail.com","kaxilpatel7298@gmail.com"] } })
          .then(response => {
            setReviews2(response.data);
          })
          .catch(error => console.error('Error fetching reviews for slider 2:', error));

          axios.get(`${apiUrl}getUniversityList`)
          .then(response => {
              console.log(response.data);
            universities(response.data);
          })
          .catch(error => console.error('Error fetching reviews for slider 2:', error));
      }, [apiUrl]);

    


      
    
    return (
        <>
            <Header />
            <section className="bg-gray">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="home-banner position-relative">
                                <h1 className="text-white">
                                    <span className="fw-600">Turning Dreams into Impact</span> <br />
                                    <span className="h1-small">
                                        <span className="fw-400"><i>You're More Than Just</i></span> <span className="fw-600">a Student</span>
                                    </span>
                                </h1>
                                <p className="text-white">
                                    As a part of this empowering global fraternity, you're not just studying abroad, you're making a difference. <br />
                                    Participate in our survey and share your experience for the next Indian student.
                                </p>

                                <div className="row banner-form">
                                    <div className="col-lg-12 m-0 p-0">
                                        <div className="input-group me-2 rubik">
                                            <span className="input-group-text border-0" id="basic-addon1">
                                                <img src={searchIcon} height={12} width={12}/>
                                                
                                            </span>
                                            <UniversitySelect />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 pe-0">
                                        {/* <button type="button" className="btn btn-primary">CLICK HERE</button> */}
                                    </div>
                                </div>

                                <div className="banner-txt text-white">
                                    THE INDIAN STUDENT
                                </div>

                                <div className="banner-txt2 text-white">
                                    FOLLOW US
                                </div>

                                <div className="social-media-div">
                                    <ul className="social-media-icon">
                                        <li>
                                            <a href="https://www.facebook.com/TheIndianStudentCommunity/" target="_blank" rel="noopener noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://x.com/TISCommunity" target="_blank" rel="noopener noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/the-indian-student/" target="_blank" rel="noopener noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/theindianstudentofficial/" target="_blank" rel="noopener noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/@TheIndianStudent" target="_blank" rel="noopener noreferrer">
                                                <svg viewBox="0 -62 512.00199 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M334.81 170.99l-113.11-61.89c-6.5-3.56-14.19-3.43-20.57.35-6.38 3.78-10.18 10.46-10.18 17.88v122.72c0 7.38 3.78 14.05 10.12 17.83 3.31 1.98 6.98 2.97 10.65 2.97 3.37 0 6.74-.83 9.85-2.5l113.12-60.82c6.71-3.61 10.91-10.59 10.94-18.22.03-7.63-4.11-14.64-10.81-18.30zm-113.86 63.62v-91.72l84.54 46.26z"/>
                                                    <path d="M508.23 91.53l-.02-.23c-.43-4.12-4.75-40.78-22.57-59.42-20.6-21.93-43.95-24.59-55.18-25.87-.93-.11-1.78-.20-2.54-.30l-.89-.09c-67.69-4.92-169.91-5.59-170.93-5.60l-.09-.00-.09.00c-1.02.00-103.25.68-171.54 5.60l-.90.09c-.73.10-1.53.19-2.40.29-11.10 1.28-34.20 3.95-54.86 26.67-16.97 18.45-21.88 54.32-22.38 58.35l-.06.52c-.15 1.71-3.77 42.54-3.77 83.52v38.31c0 40.98 3.61 81.81 3.77 83.53l.03.26c.43 4.05 4.75 40.04 22.48 58.69 19.37 21.20 43.85 24.00 57.03 25.51 2.08.24 3.88.44 5.10.66l1.18.16c39.08 3.72 161.62 5.55 166.81 5.62l.16.00.16-.00c1.02-.00 103.24-.68 170.93-5.60l.89-.09c.86-.11 1.82-.21 2.87-.32 11.04-1.17 34.02-3.61 54.39-26.02 16.97-18.45 21.88-54.32 22.38-58.35l.06-.52c.15-1.72 3.77-42.54 3.77-83.52v-38.31c-.00-40.98-3.61-81.80-3.77-83.52zm-26.24 121.84c0 37.93-3.31 77-3.63 80.59-1.27 9.88-6.45 32.57-14.72 41.56-12.75 14.03-25.85 15.42-35.41 16.43-1.16.12-2.23.24-3.20.36-65.47 4.73-163.83 5.46-168.36 5.49-5.08-.07-125.82-1.92-163.71-5.44-1.94-.32-4.04-.56-6.25-.81-11.21-1.29-26.57-3.04-38.37-16.03l-.28-.30c-8.13-8.46-13.15-29.69-14.43-41.15-.24-2.71-3.64-42.24-3.64-80.70v-38.31c0-37.89 3.30-76.91 3.63-80.57 1.52-11.64 6.79-32.96 14.72-41.57 13.14-14.45 27.00-16.05 36.16-17.11.88-.10 1.69-.20 2.45-.29 66.42-4.76 165.49-5.46 169.05-5.49 3.55.02 102.59.73 168.42 5.49.81.10 1.69.20 2.64.31 9.43 1.07 23.67 2.70 36.75 16.64l.12.13c8.13 8.46 13.15 30.06 14.43 41.75.23 2.56 3.64 42.17 3.64 80.72z"/>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-padding position-relative bg-gray">
                <img src={bgImage} alt="Background" className="bg-img" />
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="d-flex justify-content-between feature-div">
                                <div className="d-flex align-items-center">
                                    <div className="feature-img">
                                        <img src={star} alt="Star" />
                                    </div>
                                    <p className="m-0 ms-3">
                                        <span className="text-green">100+</span> reviews every month
                                    </p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="feature-img">
                                        <img src={university} alt="University" />
                                    </div>
                                    <p className="m-0 ms-3">
                                        <span className="text-green">California state university</span> is getting reviewed
                                    </p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="feature-img">
                                        <img src={graduation} alt="Graduation" />
                                    </div>
                                    <p className="m-0 ms-3">
                                        <span className="text-green">98,564</span> Indians moved to USA for Education
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sec-head mb-5 text-center">
                    University Reviews
                </div>

                <div id="uni-review">
                <Slider {...sliderSettings}>
        {reviews.map((review, index) => (
          <div className="card bg-white reviews-card position-relative h-100" key={index}>
            <div className="card-body">
              <div className="d-flex mb-4 position-relative">
                <div className="me-3">
                  <img src={userImage} alt={review.name} width="50" height="50" className="user-img" />
                </div>
                <div>
                  <h4>{review.name}</h4>
                  <h5 className="rubik">{review.university_name}</h5>
                </div>
                <div className="review-quotes">
                  <img src={quotes} alt="Quotes" width="50" height="50" />
                </div>
              </div>
              <ShowMoreText
                /* Default options */
                lines={8}
                more="Show more"
                less="Show less"
                className="content-css card-text"
                anchorClass="show-more-less-clickable show-more-less-text-color"
                onClick={executeOnClick}
                expanded={false}
                width={280}
                truncatedEndingComponent={"... "}
            >
                {review.expericence}
                </ShowMoreText>
              <img src={rating} alt="Rating" className="img-fluid" />
            </div>
          </div>
        ))}
      </Slider>
                </div>

                <div id="uni-review2">
        <Slider {...sliderSettings2}>
          {reviews2.map((review, index) => (
            <div className="card bg-white reviews-card position-relative h-100" key={index}>
              <div className="card-body">
                <div className="d-flex mb-4 position-relative">
                  <div className="me-3">
                    <img src={userImage} alt={review.name} width="50" height="50" className="user-img" />
                  </div>
                  <div>
                    <h4>{review.name}</h4>
                    <h5 className="rubik">{review.university_name}</h5>
                  </div>
                  <div className="review-quotes">
                    <img src={quotes} alt="Quotes" width="50" height="50" />
                  </div>
                </div>
                <ShowMoreText
                    lines={8}
                    more="Show more"
                    less="Show less"
                    className="content-css card-text"
                    anchorClass="show-more-less-clickable"
                    onClick={executeOnClick}
                    expanded={false}
                    width={280}
                    truncatedEndingComponent={"... "}
                >
                    {review.expericence}
                </ShowMoreText>
                <img src={rating} alt="Rating" className="img-fluid" />
              </div>
            </div>
          ))}
        </Slider>
      </div>

      

            </section>

            <section class="bg-white section-padding position-relative">
        <img src="assets/images/bg2.png" alt="" class="bg-img2" />
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="sec-head mb-5 text-center">
                        Students Migration to Popular Countries
                    </div>
                    <Charts />
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-sm-12">
                    <div class="sec-head mb-5 text-center">
                        Why Review Your University?
                    </div>
                </div>
            </div>


            <div class="row mt-5 mobile-mt-0">

                <div class="col-lg-4">
                    <div class="text-center mx-4 mobile-m">
                        <img src={sandeepImage} alt="" class="review-user" />
                        <h4 class="fw-600 mt-3 mb-4">Sandeep</h4>
                        <p>
                            After sharing my review, I realized how impactful my honest opinions could be for prospective students. There's a real value in helping others navigate their educational choices. I encourage more students to share their insights—your review could be the guiding light for someone else's journey!
                        </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="text-center mx-4 mobile-m">
                        <img src={sanikaUserImage} alt="" class="review-user" />
                        <h4 class="fw-600 mt-3 mb-4">Sanika</h4>
                        <p>
                            By leaving a review, I felt like I contributed to a larger community. It's about paying it forward—our experiences can significantly shape the decisions of future students. If you’ve gained from others' reviews like I have, sharing your own experiences can truly make a difference!
                        </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="text-center mx-4 mobile-m">
                        <img src={mihirImage} alt="" class="review-user" />
                        <h4 class="fw-600 mt-3 mb-4">Mihir</h4>
                        <p>
                            Writing my review was not just about recounting my time at the institute; it was about helping others foresee their future here. Each review we write helps build a truthful picture that can assist countless aspiring Indian students in making well-informed decisions. Let’s all contribute to this valuable resource!
                        </p>
                    </div>
                </div>

            </div>

        </div>
    </section>

    <section class="bg-white section-padding pb-0 position-relative">
        <div class="container">
            <hr/>
            <div class="row">
                <div class="col-lg-12">
                    <div class="sec-head mb-5">
                        Popular Universities
                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="review-slider">
                <Slider {...sliderSettings3}>
                    {universitiesList.map((university,index) => (
                        <div class="popular-div">
                            <div class="position-realtive">
                                <div class="img-overlay">
                                    <img src={countryImagePickr(university.country_name)} alt="" class="university-img img-fluid w-100" />
                                </div>
                                <img src={university.photo} alt="" class="university-logo" />
                                <div class="university-review ">
                                    <p>
                                        <span class="text-yellow fs-12 me-2"> {university.rating} </span><img src={rating} alt="" />
                                    </p>
                                    {/* <p class="text-white">180 Reviews</p> */}
                                </div>
                            </div>
                            <div class="university-info">
                                <h4>{university.name}</h4>
                                <p class=" fw-500 mb-0">
                                    <Link to={`/universites/`+slugify(university.name, { lower: true, strict: true })} class="text-orange stretched-link">
                                        Read More <img src={upRightImage} alt="" class="up-right" />
                                    </Link>
                                </p>
                            </div>
                        </div>
                    ))}
                </Slider>
                </div>

            </div>
        </div>
    </section>
    <Footer/>
        </>
    );
}

export default Home;
