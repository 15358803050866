import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import "../assets/css/style.css";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import linkedinLogo from "../assets/images/linkedin.png"; // Importing the LinkedIn logo
import Footer from '../components/Footer';


const linkedinURL  = process.env.REACT_APP_REACT_LINKEDIN;
const Login = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false); // State to manage loading
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        const formData = new FormData(e.target);
        const formObject = Object.fromEntries(formData.entries());
        const email = formObject.email;
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            setLoading(false); // Stop loading if there's an error
            return;
        }

        let referalId = localStorage.getItem("referal");

        setEmailError('');
        try {
            setLoading(true); // Set loading state to true when submitting form
            const response = await axios.post(`${apiUrl}sendotp`, { email,referal_id:referalId });
            if (response.data.status) {
              localStorage.setItem('signupData', JSON.stringify(formObject));
              navigate("/otp", {
                replace: true,
              });
            }else{
              return toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(
                "An error occurred while checking the email. Please try again."
            );
        }finally {
            setLoading(false); // Reset loading state when operation completes
        }
    };

    let linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?client_id=77exicvab3stae&redirect_uri=${linkedinURL}&scope=openid+profile+email&response_type=code`;
    if(localStorage.getItem("referal") !== null){
        linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?client_id=77exicvab3stae&redirect_uri=${linkedinURL}&scope=openid+profile+email&response_type=code&state=${localStorage.getItem("referal")}`;
    }

    return (
        <>
            <Header />
            <section className="section-padding">
                <ToastContainer position="top-center" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="login-form signup-form">
                                <h1>LogIn</h1>
                                <form onSubmit={handleSubmit} method="POST">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mb-4">
                                                <label htmlFor="email" className="form-label rubik">Email</label>
                                                <input type="email" className="form-control" id="email" name="email" placeholder="example.email@gmail.com" required />
                                                {emailError && <small className="text-danger">{emailError}</small>}
                                            </div>
                                        </div>
                                        <div className="mt-4 mb-3">
                                            <button type="submit" className="btn btn-primary w-100" id="next-btn" disabled={loading}>
                                                {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Login'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <p className="text-center">Don't have an account? <Link to={"/sign-up"} className="text-dark-blue fw-500">Signup</Link></p>
                                <p className="line-text"><span>OR</span></p>
                                <div className="d-flex justify-content-center mt-4">
                                    <a href={linkedinAuthUrl} className="btn btn-dark linkedin-login">
                                            Log in with <img src={linkedinLogo} alt="LinkedIn" />
                                        </a>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default Login;
