import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import Select from "react-select";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { createPopup } from "@typeform/embed";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "@typeform/embed/build/css/popup.css";
import { DeviceUUID } from "device-uuid";
import "react-toastify/dist/ReactToastify.css";
import loginImage from "../assets/images/svg/Signup.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faUser,
  faMobileScreen,
  faEnvelope,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import {
  faUser as faUserRegular,
  faEnvelope as faEnvelopeRegular,
} from "@fortawesome/free-regular-svg-icons";

const apiUrl = process.env.REACT_APP_API_URL;

const Referal = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [typeformPopup, setTypeformPopup] = useState(null);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const [degreeStatus, setDegreeStatus] = useState("");
  const [educationMode, setEducationMode] = useState("");
  const [newYearList, setNewYearList] = useState([]);
  const [currentYear] = useState(new Date().getFullYear());
  const [residenceCountryOptions, setResidenceCountryOptions] = useState([]);
  const [currentStatusOptions, setCurrentStatusOptions] = useState([]);
  const [graduationYearOptions, setGraduationYearOptions] = useState([]);
  const [countryOfStudyOptions, setCountryOfStudyOptions] = useState([]);
  const [universityOptions, setUniversityOptions] = useState([]);
  const [universityList, setInstituteNameOptions] = useState([]);
  const [cityOfStudyOptions, setCityOfStudyOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [formValueData, setFormDatas] = useState({});

  const handleTermsClick = (e) => {
    e.preventDefault();
    console.log(formData);
    console.log(formValueData);
    const formValues = { ...formData };
    formValues.institute_name = formValueData.institute_name;
    console.log(formValues);
    localStorage.setItem("signupFormValues", JSON.stringify(formValues));
    // localStorage.setItem('signupFormValues', JSON.stringify(formValueData));
    navigate("/terms", {
      replace: true,
    });
  };

  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/login", {
        replace: true,
      });
    }
    //   const fetchReferalData = async () => {
    //     try {
    //       const response = await axios.post(
    //         `${apiUrl}get-referal-data`,
    //         { id: 1 },
    //         {
    //           headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${localStorage.getItem("token")}`,
    //           },
    //         }
    //       );

    //       let typeformHiddenField = response.data.data;

    //       if(typeformHiddenField.survey_status == "N"){
    //         const hiddenField = {
    //           utm_source: "Organic",
    //           utm_medium: "Referal",
    //           utm_campaign: "FIRST",
    //           name: typeformHiddenField.name,
    //           country_of_study: typeformHiddenField.country_of_study,
    //           university_name: typeformHiddenField.institute_name,
    //           course_name: typeformHiddenField.course_type,
    //           course_type: typeformHiddenField.course_type,
    //           email: typeformHiddenField.email,
    //           source: localStorage.getItem("referal"),
    //           ip_address: typeformHiddenField.ip_address,
    //           device_id: typeformHiddenField.device_id,
    //           platform: typeformHiddenField.platform,
    //           os: typeformHiddenField.os,
    //         };
    //         const popup = createPopup("NE0FDqSO", {
    //           hidden: hiddenField,
    //           onReady: () => {
    //             console.log("Typeform ready");
    //           },
    //           onSubmit: async () => {
    //             await axios.post(`${apiUrl}update-referal-data`, typeformHiddenField);
    //             window.location.reload();
    //           },
    //         });
    //         setTypeformPopup(popup);
    //       }

    //     } catch (error) {
    //       console.error("Error fetching referral data:", error);
    //       throw error;  // rethrow if you want to handle it elsewhere
    //     }
    //   };

    // fetchReferalData();
  }, [navigate]);

  useEffect(() => {
    handleDegreeStatusChange();
  }, [degreeStatus, educationMode]);

  useEffect(() => {
    const getQueryParams = (query) => new URLSearchParams(query);
    const queryParams = getQueryParams(location.search);
    const referal = queryParams.get("referal_id");
    if (referal !== null) {
      localStorage.setItem("referal", referal);
    }
    fetchData();
  }, []);

  const handleDegreeStatusChange = () => {
    let cntr = 5;
    const years = [];

    if (degreeStatus === "C") {
      for (let i = 0; i <= 10; i++) {
        years.push({ value: currentYear - i, label: currentYear - i });
      }
      setCurrentStatusOptions([
        { value: "working", label: "Working" },
        { value: "studying_further", label: "Studying Further" },
      ]);
    } else {
      for (let i = 0; i <= cntr; i++) {
        years.push({ value: currentYear + i, label: currentYear + i });
      }
      setCurrentStatusOptions([{ value: "student", label: "Student" }]);
    }

    setNewYearList(years);
    setGraduationYearOptions(years);
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "degree_status") {
      setDegreeStatus(value);
      if (value === "P" && educationMode === "offline") {
        document.getElementById("residence_country").parentNode.style.display =
          "none";
      } else {
        document.getElementById("residence_country").parentNode.style.display =
          "block";
      }
    } else if (name === "education_mode") {
      setEducationMode(value);
      if (degreeStatus === "P" && value === "offline") {
        document.getElementById("residence_country").parentNode.style.display =
          "none";
      } else {
        document.getElementById("residence_country").parentNode.style.display =
          "block";
      }
    }
  };

  const removeDuplicates = (data, key) => {
    return [...new Map(data.map((item) => [item[key], item])).values()];
  };

  const fetchData = async () => {
    try {
      let universityResponse = await axios.get(`${apiUrl}universities`);
      console.log(universityResponse);

      let universities = universityResponse.data.data.map((value) => ({
        value: value.id,
        label: value.name.trim(),
        country_code: value.country_code,
      }));

      let countries = universityResponse.data.data.map((value) => ({
        value: value.country_code.trim(),
        label: value.country_name.trim(),
      }));

      console.log(universityResponse.data.data);
      let cities = universityResponse.data.data
        .filter((value) => value.city_name !== null)
        .map((value) => ({
          value: value.country_code.trim(),
          label: value.city_name,
          country_code: value.country_code,
        }));

      universities = removeDuplicates(universities, "label");
      countries = removeDuplicates(countries, "label");
      cities = removeDuplicates(cities, "label");

      setUniversityOptions(universities);
      setCountryOfStudyOptions(countries);
      setCityOfStudyOptions(cities);

      const newCountry = { value: "in", label: "India" };
      countries.push(newCountry);
      setResidenceCountryOptions(countries);
      const savedFormValues = JSON.parse(
        localStorage.getItem("signupFormValues")
      );

      console.log(countries);
      console.log(savedFormValues);

      let countryCode = countries.find(
        (option) => option.label === savedFormValues.country_of_study
      );
      if (countryCode !== undefined) {
        countryCode = countryCode.value;
        let new_filtered_universityList = [];
        new_filtered_universityList = universities.filter((temp) =>
          temp.country_code.includes(countryCode)
        );
        let new_filtered_cityList = [];
        new_filtered_cityList = cities.filter((temp) =>
          temp.country_code.includes(countryCode)
        );
        console.log(new_filtered_cityList);
        if (new_filtered_universityList.length >= 1) {
          setInstituteNameOptions(new_filtered_universityList);
        }

        if (new_filtered_cityList.length >= 1) {
          new_filtered_cityList.forEach((item) => {
            item.value = item.label;
          });
          setCityOfStudyOptions(new_filtered_cityList);
        }
        console.log(formValueData);
      }

      if (savedFormValues) {
        setFormData(savedFormValues);
        setFormDatas(savedFormValues);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCountryOfStudyChange = (selectedOption) => {
    if (selectedOption) {
      const countryCode = selectedOption.value;
      let new_filtered_universityList = [];
      new_filtered_universityList = universityOptions.filter((temp) =>
        temp.country_code.includes(countryCode)
      );

      let new_filtered_cityList = [];
      new_filtered_cityList = cityOfStudyOptions.filter((temp) =>
        temp.country_code.includes(countryCode)
      );
      setInstituteNameOptions(new_filtered_universityList);
      setCityOfStudyOptions(new_filtered_cityList);
      console.log(formValueData);

      setFormDatas({
        ...formValueData,
        country_of_study: selectedOption.label,
      });

      setFormData((prevData) => ({
        ...prevData,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption,
    }));
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const checkEmailExists = async (email) => {
    try {
      console.log(1);
      const response = await axios.post(`${apiUrl}checkfield`, { email });
      return response.data.status;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  const validateForm = async (formData) => {
    const errors = {};
    let isValid = true;
    setFormErrors(errors);
    if (!isValid) {
      if (errors.name) {
        toast.error(errors.name);
        return false;
      }
      if (errors.email) {
        toast.error(errors.email);
        return false;
      }
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingButton(true);
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    const inputs = e.target.querySelectorAll("input");
    console.log(inputs);
    let isError = false;
    inputs.forEach((input) => {
      const parentElement = input.parentElement.parentElement;

      if (input.type === "radio") {
        const checkedRadio = e.target.querySelector(
          `input[name="${input.name}"]:checked`
        );
        if (checkedRadio) {
          data[input.name] = checkedRadio.value;
          parentElement.classList.remove("invalid-msg-form-box");
        } else {
          isError = true;
          parentElement.classList.add("invalid-msg-form-box");
        }
      } else {
        if (!input.value) {
          if (input.className === "select__input") {
            isError = false;
          } else {
            isError = true;
            parentElement.classList.add("invalid-msg-form-box");
          }
        } else {
          if (input.className === "select__input") {
            console.log(parentElement);
          }
          parentElement.classList.remove("invalid-msg-form-box");
        }
      }
    });

    console.log(isError);

    // return false;

    // console.log(data);

    const isValid = await validateForm(data);
    if (isError) {
      setIsLoadingButton(false);
      return false;
    }

    if (isValid) {
      // Submit the form
      try {
        const data = Object.fromEntries(formData.entries());
        const getQueryParams = (query) => new URLSearchParams(query);
        const queryParams = getQueryParams(location.search);
        const referal = queryParams.get("referal_id");
        let signUpData = {
          ...data,
          city_of_study: formValueData.city_of_study,
          country_of_study: formValueData.country_of_study,
          course_type: formValueData.course_type,
          degree_type: formValueData.degree_type,
          institute_name: formValueData.institute_name,
          survey_id: "NE0FDqSO",
        };
        signUpData.referal_id = referal;
        // localStorage.setItem("email", signUpData.email);
        const ipAddress = await axios.get("https://api.ipify.org?format=json");
        signUpData.ip_address = ipAddress.data.ip;
        var uuid = new DeviceUUID().get();
        var du = new DeviceUUID().parse();
        var dua = [
          du.language,
          du.platform,
          du.os,
          du.cpuCores,
          du.isAuthoritative,
          du.silkAccelerated,
          du.isKindleFire,
          du.isDesktop,
          du.isMobile,
          du.isTablet,
          du.isWindows,
          du.isLinux,
          du.isLinux64,
          du.isMac,
          du.isiPad,
          du.isiPhone,
          du.isiPod,
          du.isSmartTV,
          du.pixelDepth,
          du.isTouchScreen,
        ];
        var uuid2 = du.hashMD5(dua.join(":"));
        signUpData.device_id = uuid2;
        signUpData.platform = dua[1];
        signUpData.os = dua[2];

        console.log(ipAddress);
        const token = localStorage.getItem("token");
        signUpData.referal_id = localStorage.getItem("referal");
        localStorage.setItem("signupFormValues", JSON.stringify(signUpData));

        const Referalresponse = await axios.post(
          `${apiUrl}referal-data`,
          signUpData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Form submitted successfully!");
        const hiddenField = {
          utm_source: "Organic",
          utm_medium: "Referal",
          utm_campaign: "FIRST",
          name: localStorage.getItem("name"),
          country_of_study: signUpData.country_of_study,
          university_name: signUpData.institute_name,
          course_name: signUpData.course_type,
          course_type: signUpData.course_type,
          email: localStorage.getItem("email"),
          source: referal,
          ip_address: signUpData.ip_address,
          device_id: signUpData.device_id,
          platform: signUpData.platform,
          os: signUpData.os,
        };
        signUpData.referal_t_id = Referalresponse.data.data.referal_t_id;
        const popup = createPopup("NE0FDqSO", {
          hidden: hiddenField,
          onReady: () => {
            console.log("Typeform ready");
          },
          onSubmit: async () => {
            await axios.post(`${apiUrl}update-referal-data`, signUpData, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
            localStorage.setItem("is_show_referal", "show");

            navigate("/referals", {
              replace: true,
            });
            window.location.reload();
          },
        });
        setTypeformPopup(popup);
        // localStorage.removeItem("signupFormValues");
        setIsLoadingButton(false);
        // navigate('/otp', {
        //   replace: true,
        // });
      } catch (error) {
        setIsLoadingButton(false);
        console.error("Error:", error.response.data.data.error);
        if (error.response.data.data.error === undefined) {
          toast.error("Failed to Submit the form");
        } else {
          toast.error(error.response.data.data.error);
        }
      }
    } else {
      setIsLoadingButton(false);
      // toast.error('Please fix the errors before submitting.');
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px", // Set the font size of options to a smaller size
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px", // Set the font size of the selected value to a smaller size
    }),
  };

  const degreeList = [
    { value: 1, label: "Bachelors" },
    { value: 2, label: "Masters" },
    { value: 3, label: "PHD" },
    { value: 4, label: "Short Course" },
  ];

  const courseList = [
    { value: 1, label: "Economics" },
    { value: 2, label: "Political Science and International Affairs" },
    { value: 3, label: "Business Administration" },
    { value: 4, label: "Computer and Information Science" },
    { value: 5, label: "Industrial Engineering and Systems Management" },
    { value: 6, label: "Law" },
    { value: 7, label: "Public Health Program" },
    {
      value: 8,
      label:
        "Master of Business Administration in Tourism and International Business",
    },
    { value: 9, label: "Human Resource Management" },
    { value: 10, label: "International Business" },
    { value: 11, label: "Management for Engineers" },
    { value: 12, label: "Marketing" },
    { value: 13, label: "Master of Business Administration" },
    { value: 14, label: "Professional Accounting" },
    { value: 15, label: "Project Management" },
    { value: 16, label: "Informatics" },
    { value: 17, label: "Information Systems" },
    { value: 18, label: "Information Technology" },
    { value: 19, label: "Accident Investigation" },
    { value: 20, label: "Accident Phenomenology" },
    { value: 21, label: "Advanced Safety Science Practice" },
    { value: 22, label: "Health Science" },
    { value: 23, label: "Occupational Health and Safety" },
    { value: 24, label: "Podiatry Practice" },
    { value: 25, label: "Applied Science" },
    { value: 26, label: "Business" },
    { value: 27, label: "Education" },
  ];

  useEffect(() => {
    if (typeformPopup) {
      typeformPopup.open();
    }
  }, [typeformPopup]);

  return (
    <>
      <Header />
      <section className="section-padding">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-8 col-md-6 col-lg-5">
              <div className="login-form signup-form">
                <h1 className="text-center mb-4">Review Form</h1>
                <form onSubmit={handleSubmit} id="sign_up_form">
                  <p className="text-center mb-4">
                    <strong>Hey there!</strong> Your journey abroad is filled
                    with unique experiences and insights. Your input can inspire
                    and guide future Indian students dreaming of studying
                    abroad. Take a moment to shape the future of education
                    funding by sharing your story.
                  </p>

                  {/* <div className="mb-4">
              <label htmlFor="name" className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter Name"
                aria-label="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter Email"
                name="email"
                aria-label="Email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div> */}

                  <div className="mb-4">
                    <label htmlFor="degree_type" className="form-label">
                      Academic Level
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="degree_type"
                      styles={customStyles}
                      options={degreeList}
                      onChange={(selectedOption) =>
                        setFormDatas({
                          ...formValueData,
                          degree_type: selectedOption.label,
                        })
                      }
                      value={degreeList.find(
                        (option) => option.label === formData.degree_type
                      )}
                      getOptionLabel={(option) => option.label}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="country_of_study" className="form-label">
                      Country Of Study
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="country_of_study"
                      styles={customStyles}
                      options={countryOfStudyOptions}
                      onChange={handleCountryOfStudyChange}
                      value={countryOfStudyOptions.find(
                        (option) =>
                          option.label === formValueData.country_of_study
                      )}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="course_type" className="form-label">
                      Course Type
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="course_type"
                      styles={customStyles}
                      options={courseList}
                      onChange={(selectedOption) =>
                        setFormDatas({
                          ...formValueData,
                          course_type: selectedOption.label,
                        })
                      }
                      value={courseList.find(
                        (option) => option.label === formValueData.course_type
                      )}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="institute_name" className="form-label">
                      University Of Study
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="institute_name"
                      styles={customStyles}
                      options={universityList}
                      onChange={(selectedOption) =>
                        setFormDatas({
                          ...formValueData,
                          institute_name: selectedOption.label,
                        })
                      }
                      value={universityList.find(
                        (option) =>
                          option.label === formValueData.institute_name
                      )}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="city_of_study" className="form-label">
                      City Of Study
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="city_of_study"
                      styles={customStyles}
                      options={cityOfStudyOptions}
                      onChange={(selectedOption) =>
                        setFormDatas({
                          ...formValueData,
                          city_of_study: selectedOption.label,
                        })
                      }
                      value={cityOfStudyOptions.find(
                        (option) => option.label === formValueData.city_of_study
                      )}
                    />
                  </div>
                  {/* 
                  <div className="form-check mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="tnc_acceptance"
                      name="tnc_acceptance"
                      value="Y"
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="tnc_acceptance"
                    > */}
                  {/* <Link to="/terms" target="_blank" className="text-dark-blue">I accept the terms and conditions</Link> */}
                  {/* </label> */}
                  {/* </div> */}

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      disabled={isLoadingButton}
                    >
                      {isLoadingButton ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Referal;
