import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import ReferralPage from "../components/ReferralPage";
import MilestoneReferral from "../components/MilestoneReferral";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Footer from "../components/Footer";
import styles from "../assets/css/ReferralPage.module.css"; // Import scoped CSS

const ReferalViralLoops = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState(
    "Data fetched un-successfully!"
  );
  const navigate = useNavigate();
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    if(!localStorage.getItem("token")) {
      navigate("/login", {
        replace: true,
      });
    }else{
      if(localStorage.getItem("is_show_referal") !== "show"){
        navigate("/home",{
          replace: true,
        });
      }
    }
  }, [navigate]);
  

  return (
    <>
      <Header />
      {localStorage.getItem("campaign_id") === "2" ? <ReferralPage /> : <MilestoneReferral />}
      <Footer />
    </>
  );
};

export default ReferalViralLoops;
