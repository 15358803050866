import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import TermsImage from "../assets/images/home/icons/Terms.svg";

const Terms = () => {
    return(
        <>
            <Header/>
            <section className="py-4">
                <div className="container text-center">
                    <img src={TermsImage} alt="Terms" className="img-fluid" width="66" />
                    <h1 className="fw-semibold fs-28 dark-purple mb-0 mt-1">Terms & Conditions</h1>
                </div>
            </section>
            <section className="sec-pad pt-0 p-75">
                <div className="container">
                    <div className="row mt-3 mt-sm-5">
                        <div className="col-12">
                            <h1>Terms and Conditions</h1>
                            <p>Last updated: February 16, 2024</p>
                            <p>Please read these terms and conditions carefully before using Our Service.</p>
                            <h2>Interpretation and Definitions</h2>
                            <h3>Interpretation</h3>
                            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                            <h3>Definitions</h3>
                            <p>For the purposes of these Terms and Conditions:</p>
                            <ul>
                                <li>
                                    <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
                                </li>
                                <li>
                                    <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                                </li>
                                <li>
                                    <p><strong>Country</strong> refers to: Maharashtra, India</p>
                                </li>
                                <li>
                                    <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Auxilo Finserve Pvt Ltd, Auxilo Finserve Pvt. Ltd. Office No. 63, 6th floor, Kalpataru Square, Kondivita Road, Andheri East, Mumbai 400059.</p>
                                </li>
                                <li>
                                    <p><strong>Content</strong> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</p>
                                </li>
                                <li>
                                    <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                                </li>
                                <li>
                                    <p><strong>Feedback</strong> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.</p>
                                </li>
                                <li>
                                    <p><strong>Promotions</strong> refer to contests, sweepstakes or other promotions offered through the Service.</p>
                                </li>
                                <li>
                                    <p><strong>Service</strong> refers to the Website.</p>
                                </li>
                                <li>
                                    <p><strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</p>
                                </li>
                                <li>
                                    <p><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</p>
                                </li>
                                <li>
                                    <p><strong>Website</strong> refers to The Indian Student, accessible from <a href="https://theindianstudent.com/" rel="external nofollow noopener" target="_blank">https://theindianstudent.com</a></p>
                                </li>
                                <li>
                                    <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                                </li>
                            </ul>
                            <h2>Acknowledgment</h2>
                            <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
                            <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
                            <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
                            <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
                            <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
                            <h2>Promotions</h2>
                            <p>Any Promotions made available through the Service may be governed by rules that are separate from these Terms.</p>
                            <p>If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>
                            <h2>User Accounts</h2>
                            <p>When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.</p>
                            <p>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>
                            <p>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</p>
                            <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
                            <h2>Content</h2>
                            <h3>Your Right to Post Content</h3>
                            <p>Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.</p>
                            <p>By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms.</p>
                            <p>You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>
                            <h3>Content Restrictions</h3>
                            <p>The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under Your account, whether done so by You or any third person using Your account.</p>
                            <p>You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:</p>
                            <ul>
                                <li>Unlawful or promoting unlawful activity.</li>
                                <li>Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.</li>
                                <li>Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.</li>
                                <li>Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.</li>
                                <li>Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.</li>
                                <li>Impersonating any person or entity including the Company and its employees or representatives.</li>
                                <li>Violating the privacy of any third person.</li>
                                <li>False information and features.</li>
                                <li>Disguising the origin of any content.</li>
                                <li>Collecting, harvesting, obtaining or processing personal data of others without their consent.</li>
                                <li>Forcing third parties to download files.</li>
                                <li>Unlinkable malware.</li>
                            </ul>
                            <p>The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner any Content. The Company can also limit or revoke the use of the Service if you post such objectionable content. </p>
                            <h1>Referral Program Terms and Conditions</h1>
    
    <h2>1. Eligibility:</h2>
    <p>Only active members of the TIS community are eligible to participate in the Referral Program.</p>
    <p>Members must be of legal age to participate in the referral program according to their jurisdiction.</p>
    
    <h2>2. Referral Process:</h2>
    <p>Members can refer new users to the community using their unique referral link or code.</p>
    <p>The referral link or code will be provided to members upon joining the community.</p>
    <p>The referred user must sign up for the community using the provided referral link or code.</p>
    
    <h2>3. Referral Rewards:</h2>
    <p>Members will receive a reward for every 10, 20, and 30 authentic users who sign up using their referral link or code.</p>
    <ul>
        <li>The reward for 10 referrals is $10.</li>
        <li>The reward for 20 referrals is $20.</li>
        <li>The reward for 30 referrals is $30.</li>
    </ul>
    <p>Rewards will only be given for authentic user sign-ups.</p>
    <p>There is a maximum reward of $30 per member, regardless of the number of referrals.</p>
    
    <h2>4. Reward Redemption:</h2>
    <p>Rewards will be paid out [specify payment method, e.g., PayPal, gift card] upon reaching the required referral threshold.</p>
    <p>Members must provide valid payment information to receive their rewards.</p>
    
    <h2>5. Fraud and Abuse:</h2>
    <p>The community reserves the right to investigate and take action against any member suspected of engaging in fraudulent or abusive referral practices.</p>
    <p>This includes, but is not limited to, creating fake accounts, buying or selling referrals, or using automated methods to generate referrals.</p>
    <p>If a member is found to be engaging in fraudulent or abusive practices, their rewards may be forfeited, and their membership may be terminated.</p>
    
    <h2>6. Changes to the Program:</h2>
    <p>The community reserves the right to modify, suspend, or terminate the Referral Program at any time, with or without notice.</p>
    
    <h2>7. Governing Law:</h2>
    <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of [Jurisdiction].</p>
    
    <h2>8. Contact Information:</h2>
    <p>For any questions or concerns regarding the Referral Program, please contact <a href="mailto:support@theindianstudent.com">support@theindianstudent.com</a>.</p>
                        
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Terms;