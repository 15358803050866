import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for making API requests
import styles from "../assets/css/ReferralPage.module.css"; // Import scoped CSS
import MilestoneReferral from "../components/MilestoneReferral";

const apiUrl = process.env.REACT_APP_API_URL;
const shareUrl = process.env.REACT_SHARE_URL;

const ReferralPage = () => {
  const [referralLink] = useState(
    `https://theindianstudent.com/#/home?referal_id=${localStorage.getItem(
      "email"
    )}`
  );
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // State to control how many items are visible initially

  // Function to copy referral link
  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    alert("Referral link copied to clipboard!");
  };

  // Fetch leaderboard data
  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}leaderboard`,
          { params: { campaign_id: 1 } },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setLeaderboard(response.data.data.leaderboard);
        setTotalReferrals(response.data.data.total_referal);
      } catch (error) {
        console.error("Error fetching leaderboard data", error);
      }
    };

    fetchLeaderboard();
  }, []);

  // Function to show more leaderboard items
  const showMoreLeaderboard = () => {
    setVisibleCount((prevCount) => prevCount + 5); // Increase visible count by 5 or any desired number
  };

  return (
    <div className={styles.container}>
      <h1>Invite your friends to win rewards</h1>
      <p>Copy and share your referral link</p>

      {/* Referral Link Section */}
      <div className={styles.referralLinkSection}>
        <input
          type="text"
          value={referralLink}
          readOnly
          className={styles.referralInput}
        />
        <button className={styles.copyButton} onClick={copyReferralLink}>
          Copy
        </button>
      </div>

      {/* Social Share Icons */}
      <div className={styles.socialIcons}>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            referralLink
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://img.icons8.com/ios-filled/24/facebook--v1.png"
            alt="Facebook"
          />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?text=Just%20discovered%20an%20amazing%20platform%20and%20immediately%20thought%20of%20you!%20It's%20perfect%20for%20networking,%20learning,%20and%20sharing%20insights.%20I’m%20already%20loving%20it%20and%20I%20think%20you%20will%20too.%20Check%20it%20out%20with%20this%20invite:%20${encodeURIComponent(
            referralLink
          )}%20#JoinMe%20#LearningTogether`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://img.icons8.com/ios-filled/24/twitter-circled--v1.png"
            alt="Twitter"
          />
        </a>
        <a
          href={`https://api.whatsapp.com/send?text=Hey!%20I've%20found%20this%20incredible%20platform%20that%20I%20think%20you'll%20really%20enjoy%20being%20a%20part%20of.%20It's%20a%20great%20space%20for%20learning,%20sharing,%20and%20connecting%20with%20others.%20I've%20already%20signed%20up%20and%20thought%20of%20you.%20Give%20it%20a%20try!%20Here’s%20your%20invite%20link:%20${encodeURIComponent(
            referralLink
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://img.icons8.com/ios-filled/24/whatsapp--v1.png"
            alt="WhatsApp"
          />
        </a>
        <a
          href={`mailto:?subject=Check%20out%20this%20platform&body=Hi!%20I've%20found%20this%20incredible%20platform%20that%20I%20think%20you'll%20really%20enjoy%20being%20a%20part%20of.%20It's%20a%20great%20space%20for%20learning,%20sharing,%20and%20connecting%20with%20others.%20I've%20already%20signed%20up%20and%20thought%20of%20you.%20Give%20it%20a%20try!%20Here’s%20your%20invite%20link:%20${encodeURIComponent(
            referralLink
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://img.icons8.com/ios-filled/24/email-open.png"
            alt="Email"
          />
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            referralLink
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://img.icons8.com/ios-filled/24/linkedin.png"
            alt="LinkedIn"
          />
        </a>
      </div>

      {/* Referral Count */}
      <div className={styles.referralCount}>
        <div className={styles.countBox}>
          {totalReferrals}
          <br />
          Referrals
        </div>
      </div>

      {/* Leaderboard Section */}
      <div className={styles.leaderboard}>
        <h2>Leaderboard</h2>
        <ol>
          {leaderboard.slice(0, visibleCount).map((user, index) => (
            <li key={index}>{user.name}</li>
          ))}
        </ol>
        {visibleCount < leaderboard.length && (
          <button
            className={styles.showMoreButton}
            onClick={showMoreLeaderboard}
          >
            Show more
          </button>
        )}
        <p className={styles.leaderboardNote}>
          Come back to our page to check your place in the leaderboard.
        </p>
      </div>

      {/* Footer */}
    </div>
  );
};

export default ReferralPage;
